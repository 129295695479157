<template>
    <div>
        <!--
        <div class="my-n3 my-md-n5 mx-n3 subheader">

            <div class="subheader-item" :class="{'active': $route.path.includes('/overview')}"
                 v-on:click="$router.push('/dashboard/overview')">
                Overview
            </div>
            <div class="subheader-item" :class="{'active': $route.path.includes('/pools')}"
                 v-on:click="$router.push('/dashboard/pools')">
                Pools
            </div>
            <div class="subheader-item" :class="{'active': $route.path.includes('/arbitrage')}"
                 v-on:click="$router.push('/dashboard/arbitrage')">
                Arbitrage
            </div>
            <div class="subheader-item" :class="{'active': $route.path.includes('/tokens')}"
                 v-on:click="$router.push('/dashboard/tokens')">
                Tokens
            </div>

            <div class="subheader-item" :class="{'active': $route.path.includes('/transactions')}"
                 v-on:click="$router.push('/dashboard/transactions')">
                Transactions
            </div>

        </div>
        <div class="mt-3 mt-md-5 pt-5"></div>
        -->

        <b-row align-h="center" class="">
            <b-col>
                <b-overlay>
                    <router-view/>
                </b-overlay>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    export default {
        name: "Dashboard"
    }
</script>

<style scoped lang="scss">
    .subheader {
        min-height: 48px;
        background-color: $gray-300;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
    }

    .subheader-item {
        padding: 8px 14px;
        border-radius: 12px;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $white;
            font-weight: 700;
        }
    }

</style>
